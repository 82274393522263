import React, {useState} from "react";
import logoColor from "../../assets/sydlogocolor.svg"
import { Link } from "react-scroll"
import "./Header.css"

const Header = () => {

    //setearle un state al dropdown y que cuando se haga click se ponga en false y cuando se aprete dropdown este en true
    const [click, setClick] = useState(false)
   /*  const [dropdown, setDropdown] = useState(false) */

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const goUp = () => {
      window.scrollTo({
       top: 0,
       behavior: "smooth"
     })
    }
  
    /* const onMouseEnter = () => {
      if (window.innerWidth < 960) {
        setDropdown(false);
      } else {
        setDropdown(true);
      }
    };
  
    const onMouseLeave = () => {
      if (window.innerWidth < 960) {
        setDropdown(false);
      } else {
        setDropdown(false);
      }
    }; */

    return (
        <>
            <header className="Header-container">
            <div className="Header-logo-container" onClick={goUp}>
                <img className="logoColor" src={logoColor} alt="logoSYD"/>
            </div> 
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <div className={click ? 'nav-menu active' : 'nav-menu'}>
              <div className="nav-item">
                  <Link to="bio" smooth={true} className='nav-links'
                        onClick={closeMobileMenu}>BIO
                </Link>
              </div>
              <div className="nav-item">
                  <Link to="tour" smooth={true} className='nav-links'
                        onClick={closeMobileMenu}>TOUR
                </Link>
              </div>
              <div className="nav-item">
                  <Link to="contacto" smooth={true} className='nav-links'
                        onClick={closeMobileMenu}>CONTACTO
                </Link>
              </div>
              <div className="nav-item">
                  <Link to="galeria" smooth={true} className='nav-links-galeria'
                        onClick={closeMobileMenu}>GALERIA
                </Link>
              </div>
            </div>
            </header>
        </>
    )
}

export default Header;


/* <header className="Header-container">
    <input id="nav-toggle" type="checkbox"></input>
    <div className="Header-logo-container">
        <img  className="logoColor" src={logoColor}/>
    </div> 
    <div className="Header-column">
        <Link to="/bio" smooth={true}>
            <h2 className="Header-text">BIO</h2>
        </Link>
        <Link to="/tour" smooth={true}>
            <h2 className="Header-text ">TOUR</h2>
        </Link>
        <Link to="/contacto" smooth={true}>
            <h2 className="Header-text">CONTACTO</h2>   
        </Link>
        <Link to="/gallery" smooth={true}>
            <h2 className="Header-text ">GALERIA</h2>
        </Link>
    </div>
    <div className="Header-dropdown-container">
        <img className="Header-dropdown" src={dropdown} />
    
    </div>
    <label for="nav-toggle" class="Header-dropdown">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </label>
</header> */