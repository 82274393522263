import React from "react";
import "./Bio.css";
import tira from "../../assets/tirafondo.png";
import gaston from "../../assets/gastonfondo.png"
import diego from "../../assets/diegofondo.png"
import grupo from "../../assets/grupobio.png"

const Bio = () => {
    return (
        <section id="bio">
            <div className="BioContainer">
                <div className="Bio-general">
                    <h1 className="Bio-title">B I O</h1>
                    <div className="Bio-image-text-container">
                        <div className="BioText">
                            <h2 className="BioBandName">SUCIO Y DESPROLIJO</h2>
                            <h3 className="BioBandTribute">Tributo Homenaje a Norberto "Pappo" Napolitano</h3>
                            <span className="BioFirstParagraph">Allá por el 2006 en la ciudad de Mar del Plata, un año después del fallecimiento de Pappo, Fernando “El Tira” Arseni, bajista, se encontraba trabajando en una productora que organizaría un festival en homenaje al Carpo. Allí conoció a Gastón Arrúa, guitarrista y voz, y lo convocó, junto a Mario Pasculli en batería, para formar Sucio y Desprolijo, homenajeando a su máximo referente del rock argentino. En palabras de El Tira: <span className="Bio-phrase-italic">“Tanto Gastón (Arrúa) como yo éramos amigos de Pappo y la intención era recordarlo con respeto y toda la bendición que sentíamos que teníamos de él.” </span>Por cinco años, Fernando “Chicho” Romairone, destacado músico marplatense, fue su baterista. Sucio y Desprolijo le agradece por pertenecer y aportar su talento y personalidad. En Febrero de 2024, le dieron la bienvenida a "El Eficaz", Diego Zamorano, para continuar con su legado.</span>
                            <br/>
                            <span className="BioSecondParagraph">Este reconocido <strong>tributo homenaje a Pappo y Riff</strong> toca ininterrumpidamente desde hace dieciséis años, y cuenta con innumerables presentaciones en la ciudad de Mar del Plata y la provincia de Buenos Aires.</span>
                            <br/>
                            <span className="BioThirdParagraph">En el año 2016, SyD realizó su primera fusión como banda de rock invitada junto con la Banda Sinfónica Municipal de General Pueyrredón, conformada por 45 músicos. La sofisticada apoyatura técnica brindada por el director José María Ulla, permitió que ambas formaciones se mezclen en una sonoridad acorde a cada canción, dando como resultado un show de alto nivel en el que se produjo un trabajo de coordinación y nivelación del sonido para que la potencia del rock no opaque la sutileza de los instrumentos orquestales.</span>
                            <br />
                        </div>
                        <div className="Bio-image-and-phrase">
                            <img className="Bio-image-group" src={grupo} alt="bio group"/>
                            <span className="Bio-phrase">“Es una cuestión de honor estar recordando a semejante tipo y músico. Sucio y Desprolijo vive recordándolo.”</span>
                        </div>
                    </div>
                    
                </div>
                <div className="BioMembers">
                    <h1 className="Members-title">I N T E G R A N T E S</h1>
                        <div className="BioChicho">
                            <img className="BioMembersImage-chicho" src={diego} alt="bio Diego"/>
                                <span className="BioMembersInfo"><strong>Diego Zamorano</strong> marplatense, inició sus estudios en el Conservatorio Luis Gianneo con el Maestro Daniel Izarriaga, continuando en la Escuela de Música Popular de Tandil y con distintos profesores. En su carrera su versatilidad le ha permitido participar en múltiples proyectos, con músicos tanto locales como nacionales entre los que se encuentran Insoluble, Jorge Armani, Mario Orbe, Séquito Arrogante, Luis Robinson, Daniel Raffo, Alambre González, entre muchos otros. Se dedica a la docencia del instrumento desde hace más de 20 años.</span>
                        </div>
                        <div className="Bio-tira-gaston">
                            <div className="BioTira">
                                <img className="BioMembersImage" src={tira} alt="bio Tira"/>
                                    <span className="BioMembersInfoVertical"><strong>Fernando “El Tira” Arseni</strong>, bajista, compositor y productor, comenzó a los 15 años tomando clases en el conservatorio de música de Mar del Plata, y clases particulares de bajo. Entre 1979 y 1988 fue parte de Némesis, banda pionera del rock marplatense reconocida por la Secretaría de Cultura, con la que además de una gran cantidad de conciertos y presentaciones, realizó dos giras a Brasil, y su reunión en 2014 y 2016. En 2016 formó Arseni Special, en palabras del propio Arseni “una banda de orígenes rockeros con tendencias al Rhythm and Blues, Blues y Hard Rock”, junto a Mario Pasculli y Diego Crego, que continúa hasta la actualidad. Su trayectoria musical también incluye los grupos Bandavión, Paff, Robar Trío, Striptease, La Dorrego, Kangas, Orbe Blues, Patagonia, Arsénico, y Boff y los Repuestos junto a Boff Serafine, guitarrista de Riff. Fue manager del grupo Crack en 1994. Participó de festivales tales como el Festival de la Juventud en 1981, el Festi Camping de Rock en 1987 y 1988, y el Festival del teatro Pigalle en 1990. Ha producido eventos como Café Concert Veronelli Aráoz, Juan Marcelo y Paula Domínguez en 1991, Horacio Fontova y Leo Maslíah en 1989, y eventos en el Domo para el Atlántico en 1993. Organizó y produjo en 2021 el 40° Aniversario del primer Festival de la Juventud realizado en Villa Victoria. En 2017 recibió el Premio a la trayectoria Deportea "Al Maestro". </span>
                            </div>
                            <div className="BioGaston">
                                <img className="BioMembersImage" src={gaston} alt="bio Gaston"/>
                                    <span className="BioMembersInfoVertical"><strong>Gastón Arrua</strong>, gran guitarrista autodidacta, cuya escuela musical gira en torno al Rock Argentino. Fue amigo de Pappo, al que considera “un modelo a seguir”; es un gran conocedor de la historia del Carpo, tanto musical como anecdótica. Tocó junto a grandes personajes del Rock Nacional como Alejandro Medina (bajista de Manal), JAF, Claudio Gabis (guitarrista de Manal) y Willy Quiroga (bajista de Vox Dei). Forma parte de La Puntera Royal desde el año 2000, banda de rock donde desarrolla su música.</span>
                            </div>
                            <div className="BioDiegoResponsive">
                                <img className="BioMembersImage-chicho" src={diego} alt="bio Diego"/>
                                <span className="BioMembersInfoVertical"><strong>Diego Zamorano</strong> baterista marplatense, inició sus estudios en el Conservatorio Luis Gianneo con el Maestro Daniel Izarriaga, continuando en la Escuela de Música Popular de Tandil y con distintos profesores. En su carrera su versatilidad le ha permitido participar en múltiples proyectos, con músicos tanto locales como nacionales entre los que se encuentran Insoluble, Jorge Armani, Mario Orbe, Séquito Arrogante, Luis Robinson, Daniel Raffo, Alambre González, entre muchos otros. Se dedica a la docencia del instrumento desde hace más de 20 años.</span>
                            </div>
                        </div>
                </div>
                <div className="Rider-tecnico">
                    <div className="Rider-title">
                    <h1 className="Rider-text">R I D E R T É C N I C O</h1>
                    </div>
                    <span className="Rider-info">Diez canales</span>
                    <span className="Rider-info">Bajo y Guitarra dos líneas | Dos voces | Seis micrófonos o canales batería (Bombo/Redoblante/Tones/Aéreos)</span>
                </div>
            </div>
        </section>
    )
}

export default Bio;