import React from "react";
import "./Contact.css"
import Facebook from "../../assets/FACEBOOK.svg"
import Instagram from "../../assets/INSTAGRAM.svg"
import WhatsApp from "../../assets/WHATSAPP.svg"
import Mail from "../../assets/MAIL.svg"
import Youtube from "../../assets/YOUTUBE.svg" 

const Contact = () => {
    return (
        <section id="contacto" className="Contact-container">
                <h1 className="Contact-title">C O N T A C T O</h1>
                <div className="Contact-second-container">
                    <div className="Comunicate-container">
                        <h2 className="Comunicate-title">COMUNICATE CON NOSOTROS</h2>
                        <div className="Contact-logo-and-text">
                            <a className="Contact-social-media" href="https://wa.me/542235292972?text=Hola,%20quisiera%20comunicarme%20con%20Sucio%20y%20desprolijo%20" target="_blank"><img className="Contact-social-icons" src={WhatsApp} /><span className="Contact-texts">+54 223 5292972</span></a>
                            <a className="Contact-social-media" href="https://wa.me/542235389060?text=Hola,%20quisiera%20comunicarme%20con%20Sucio%20y%20desprolijo%20" target="_blank"><img className="Contact-social-icons" src={WhatsApp} /><span className="Contact-texts">+54 223 5389060</span></a>
                            <a className="Contact-social-media" href="mailto:sucioydesprolijomdq@gmail.com" target="_blank"><img className="Contact-social-icons" src={Mail} /><span className="Contact-texts">sucioydesprolijomdq@gmail.com</span></a>
                        </div>
                    </div>
                    <div className="Seguinos-container">
                        <h2 className="Seguinos-title">SEGUINOS</h2>
                        <div className="Contact-logo-and-text">
                            <a className="Contact-social-media" href="https://www.instagram.com/sucioydesprolijomdq/" target="_blank"><img className="Contact-social-icons" src={Instagram} /><span className="Contact-texts">@sucioydesprolijomdq</span></a>
                            <a className="Contact-social-media" href="https://www.facebook.com/profile.php?id=100032449856790" target="_blank"><img className="Contact-social-icons" src={Facebook} /><span className="Contact-texts">Sucio y Desprolijo SYD</span></a>
                            <a className="Contact-social-media" href="https://www.youtube.com/@sucioydesprolijomdq8044" target="_blank"><img className="Contact-social-icons" src={Youtube} /><span className="Contact-texts">Sucio y Desprolijo (MDQ)</span></a>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Contact