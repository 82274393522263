import './App.css';
import Contact from './components/Contact/Contact';
import Bio from "./components/Bio/Bio";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer"
import Header from './components/Header/Header';
import Tour from './components/Tour/Tour';
import CarouselSyd from './components/Carousel/Carousel';
import Footer from './components/Footer';

const App = () => {

  return (
    <div>
        <VideoPlayer />
       <div>
        <Header className="header-app"/>
      <div className='all-components'>
        <Bio />
        <Tour />
        <Contact />
        <CarouselSyd className="pc-carousel"/>
        <Footer />
       </div>
      </div>
    </div>
  )
}

export default App;































