import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import pic1 from "../Album/ImgAlbum/marea1.png";
import pic2 from "../Album/ImgAlbum/marea2.png";
import pic3 from "../Album/ImgAlbum/sinfonica1.png";
import pic4 from "../Album/ImgAlbum/sinfonica2.png";
import pic5 from "../Album/ImgAlbum/sinfonica3.png";
import pic6 from "../Album/ImgAlbum/willyquiroga.png";
import pic7 from "../Album/ImgAlbum/alejandromedina1.png";
import pic8 from "../Album/ImgAlbum/alejandromedina2.png";
import pic9 from "../Album/ImgAlbum/jaf1.png";
import pic10 from "../Album/ImgAlbum/jaf2.png";
import pic11 from "../Album/ImgAlbum/claudiogabis.png";
import pic12 from "../Album/ImgAlbum/boffserafine.png";
import pic13 from "../Album/ImgAlbum/clarin.png";
import pic14 from "../Album/ImgAlbum/grupobrewhouse.png"
import pic15 from "../Album/ImgAlbum/chicho.png"
import pic16 from "../Album/ImgAlbum/gaston.png"
import pic17 from "../Album/ImgAlbum/tira.png"
import pic18 from "../Album/ImgAlbum/19.png"
import pic19 from "../Album/ImgAlbum/20.png"
import pic20 from "../Album/ImgAlbum/21.png"
import pic21 from "../Album/ImgAlbum/22.png"
import pic22 from "../Album/ImgAlbum/23.png"
import pic23 from "../Album/ImgAlbum/24.png"
import pic24 from "../Album/ImgAlbum/25.png"
import pic25 from "../Album/ImgAlbum/26.png"
import pic26 from "../Album/ImgAlbum/27.png"
import pic27 from "../Album/ImgAlbum/28.png"
import pic28 from "../Album/ImgAlbum/29.png"
import pic29 from "../Album/ImgAlbum/30.png"
import pic30 from "../Album/ImgAlbum/31.png"
import pic31 from "../Album/ImgAlbum/32.png"
import pic32 from "../Album/ImgAlbum/33.png"
import pic33 from "../Album/ImgAlbum/34.png"
import pic34 from "../Album/ImgAlbum/35.png"
import pic35 from "../Album/ImgAlbum/36.png"
import "./Carousel.css"


const CarouselSyd = () => {

    let carousel = [
        {
            id:1,
            source: pic18,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:2,
            source: pic19,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:3,
            source: pic20,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:4,
            source: pic21,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:5,
            source: pic22,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:6,
            source: pic23,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:7,
            source: pic24,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:8,
            source: pic25,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:9,
            source: pic26,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:10,
            source: pic27,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:11,
            source: pic28,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:12,
            source: pic29,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:13,
            source: pic30,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:14,
            source: pic31,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:15,
            source: pic32,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:16,
            source: pic33,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:17,
            source: pic34,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:18,
            source: pic35,
            legend:"Presentación en Hawaii Bar - Julio 2024"
        },
        {
            id:19,
            source: pic1,
            legend:"Presentación en el festival Marea Puerto - Octubre 2022"
        },
        {
            id:20,
            source: pic2,
            legend:"Presentación en el festival Marea Puerto - Octubre 2022"
        },
        {
            id:21,
            source: pic14,
            legend:"Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:22,
            source: pic15,
            legend:"Chicho Romairone - Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:23,
            source: pic16,
            legend:"Gastón Arrúa - Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:24,
            source: pic17,
            legend:"Tira Arseni - Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:25,
            source: pic3,
            legend:"Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018"
        },
        {
            id:26,
            source: pic4,
            legend:"Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018"
        },
        {
            id:27,
            source: pic5,
            legend:"Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018"
        },
        {
            id:28,
            source: pic6,
            legend:"Junto a Willy Quiroga, bajista de Vox Dei - 2022"
        },
        {
            id:29,
            source: pic7,
            legend:"Junto a Alejandro Medina, bajista de Manal - 2013"
        },
        {
            id:30,
            source: pic8,
            legend:"Junto a Alejandro Medina, bajista de Manal - 2013"
        },
        {
            id:31,
            source: pic9,
            legend:"Junto a JAF - 2014"
        },
        {
            id:32,
            source: pic10,
            legend:"Junto a JAF - 2014"
        },
        {
            id:33,
            source: pic11,
            legend:"Junto a Claudio Gabis, guitarrista de Manal"
        },
        {
            id:34,
            source: pic12,
            legend:"Junto a Boff Serafine, guitarrista de Riff"
        },
        {
            id:35,
            source: pic13,
            legend:"Presentación en el Espacio Clarín de Mar del Plata"
        }
    ]

    return (
        <section id="galeria"  className="Main-slide">
            <h1 className="Slideshow-title">GALERÍA</h1>
            <Carousel className="Carousel-container">
            <div className='menosUno'>
                    <img src={pic19} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic18} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic20} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>

                <div className='menosUno'>
                    <img src={pic21} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic22} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic23} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>

                <div className='menosUno'>
                    <img src={pic25} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic24} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic27} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>

                <div className='menosUno'>
                    <img src={pic26} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic29} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic28} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>

                <div className='menosUno'>
                    <img src={pic30} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic31} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic32} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>

                <div className='menosUno'>
                    <img src={pic33} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic34} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic35} />
                    <p className="legend">Presentación en Hawaii Bar - Julio 2024</p>
                </div>
                <div className='menosUno'>
                    <img src={pic1} />
                    <p className="legend">Presentación en el festival Marea Puerto - Octubre 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic2} />
                    <p className="legend">Presentación en el festival Marea Puerto - Octubre 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic14} />
                    <p className="legend">Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic15} />
                    <p className="legend">Chicho Romairone - Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic16} />
                    <p className="legend">Gastón Arrúa - Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic17} />
                    <p className="legend">Tira Arseni - Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic3} />
                    <p className="legend">Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018</p>
                </div>
                <div className='menosUno'>
                    <img src={pic4} />
                    <p className="legend">Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018</p>
                </div>
                <div className='menosUno'>
                    <img src={pic5} />
                    <p className="legend">Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018</p>
                </div>
                <div className='menosUno'>
                    <img src={pic6} />
                    <p className="legend">Junto a Willy Quiroga, bajista de Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic7} />
                    <p className="legend">Junto a Alejandro Medina, bajista de Manal - 2013</p>
                </div>
                <div className='menosUno'>
                    <img src={pic8} />
                    <p className="legend">Junto a Alejandro Medina, bajista de Manal - 2013</p>
                </div>
                <div className='menosUno'>
                    <img src={pic9} />
                    <p className="legend">Junto a JAF - 2014</p>
                </div>
                <div className='menosUno'>
                    <img src={pic10} />
                    <p className="legend">Junto a JAF - 2014</p>
                </div>
                <div className='menosUno'>
                    <img src={pic11} />
                    <p className="legend">Junto a Claudio Gabis, guitarrista de Manal</p>
                </div>
                <div className='menosUno'>
                    <img src={pic12} />
                    <p className="legend">Junto a Boff Serafine, guitarrista de Riff</p>
                </div>
                <div className='menosUno'>
                    <img src={pic13} />
                    <p className="legend">Presentación en el Espacio Clarín de Mar del Plata</p>
                </div>
            </Carousel>
            <div className="Carousel-phone">
            <div className="Carousel-phone-pics">
                    <div className="Carousel-phone-pics-container">
                        {carousel.map((data, index) => {
                            return (
                                <div key={index} className="Carousel-pic-and-legend-container">
                                    <div className="Carousel-pic-and-legend">
                                        <img className="Carousel-pic" src={data.source} />
                                        <span className="Carousel-legend">{data.legend}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
} 

export default CarouselSyd